import React, { useCallback, useEffect, useState } from 'react';
import styles from '../styles.module.scss';
import { Button, Modal, Table } from 'antd';
import qs from 'query-string';
import {
  BALES_STATUS,
  BALE_STATUS_BALANCE_GREIGE,
  BALE_INVENTORY_TABS as BIT,
  FULFILLED_BY,
  FULFILLED_BY_OPTIONS,
  editBalanceGreigeColumns,
  editInventoryColumns,
} from '../base';
import * as Yup from 'yup';
import InventoryManagementForm from '../InventoryManagementForm';
import Select from '../../TopQualities/Select';
import backIcon from '../../../../assets/images/navigation/ic-chevron-left-blue.svg';
import {
  LOOM_TYPES_KEY,
  PRODUCTION_SUB_ATTRIBUTE_ID,
  PRODUCTION_SUB_TYPES,
  PRODUCTION_SUB_TYPES_REVERSE,
  WEAVE_PATTERN_REVERSE,
} from 'components/AccountSettings/TopQualities/config';
import { Formik } from 'formik';
import FieldInput from 'components/AccountSettings/TopQualities/FieldInput';
import { connect } from 'react-redux';
import { getInventoryBaleData, updateBaleData , getBalanceGreigeBaleData,updateBalanceGreigeBaleData } from 'store/actions/InventoryAction';
import { getMills as getMillsAction } from 'store/actions/UserActions';
import history from 'utilites/history';
import { debounce } from 'lodash';

const EditInventory = ({ getBaleTableData, tableBaleData, updateBaleDetails, getMills, mills ,balanceGreigeBaleData,getBalanceGreigeBaleData,updateBalanceGreigeBaleData}) => {
  const validationSchema = Yup.object().shape({
    baleNumber: Yup.string().required('Please enter bale number'),
    quantity: Yup.number().required('Please enter quantity'),
    docketNumber: Yup.string().required('Please enter docket number'),
    sourcingPrice: Yup.number().required('Please enter sourcing price'),
  });

  const {
    productId,
    productName,
    warehouseName,
    warehouseLocation,
    threadcount,
    construction,
    width,
    loomType,
    warehouseId,
    totalQuantityInWarehouse,
    color,
    numberOfColors,
    productionSubTypeAttributeId,
    productionSubType,
    weaveTypeId,
    minWidth,
    supplierId,
    millName,
    totalQuantityInMill,
    isBalanceGreige=false
  } = JSON.parse(qs.parse(history.location.search).record) || {};

  const [openInventoryForm, setOpenInventoryForm] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState({ visibility: false, record: null });
  const [openEditModal, setOpenEditModal] = useState({ visibility: false, record: null });
  const [discardModal, setDiscardModal] = useState({ visibility: false, record: null });
  const [loader, setLoader] = useState(false);
  const [tab, setTab] = useState(BIT.AVAILABLE);

  const reloader = () => {
    setLoader(!loader);
  };

  const handleEditBale = ({ record = {}, formValues = {} }) => {
    const {
      id,
      baleNumber: baleNumRecord,
      quantity: quantityRec,
      supplierId: supplierIdRec,
    } = record;

    const { baleNumber, docketNumber, quantity, sourcingPrice, supplierId, fulfilledBy } =
      formValues;

    let formData = {
      ...(baleNumRecord !== baleNumber && { baleNumber }),
      ...(quantityRec !== quantity && { quantity: Number(quantity) }),
      ...(supplierIdRec !== supplierId && { supplierId }),
      docketNumber,
      sourcingPrice: Number(sourcingPrice),
      fulfilledBy: +fulfilledBy,
    };

    updateBaleDetails({ baleId: id, formData }).then((dd) => {
      setOpenEditModal({ visibility: false, record: null });
      reloader();
    });
  };

  const handleDeleteBale = ({ id: baleId }) => {
    let formData = {
      isDeleted: 1,
    };
    updateBaleDetails({ baleId, formData }).then((dd) => {
      setOpenDeleteModal({ visibility: false, record: null });
      reloader();
    });
  };

  const handleStatusChange = ({ baleId, status }) => {
    let formData = {
      status: +status,
    };
    const updateFunction = isBalanceGreige ? updateBalanceGreigeBaleData : updateBaleDetails;
    updateFunction({ baleId, formData }).then((dd) => {
      setDiscardModal({ visibility: false, record: null });
      reloader();
    });
  };

  const baleColumnFilter = (columnData) => {
    return columnData.tab !== tab;
  };

  useEffect(() => {
    if (isBalanceGreige) {
      getBalanceGreigeBaleData({ productId, supplierId, params: { tab } });
    } else {
      getBaleTableData({ productId, warehouseId, params: { tab } });
    }
    // debouncedSupplierSearch('');
  }, [tab, loader]);  

  const debouncedSupplierSearch = useCallback(
    debounce((nextValue) => getMills({ search: nextValue }), 350),
    [],
  );

  return (
    <div className={styles.page}>
      <div className={styles.head2}>
        <div
          className={styles.pointer}
          onClick={() => {
            history.goBack();
          }}>
          <img src={backIcon} alt="back icon" />
        </div>
        <div className={styles.headline2}>{`${productName} ${
          totalQuantityInWarehouse && tab === BIT.AVAILABLE
            ? `(${+totalQuantityInWarehouse} m)`
            : ''
        }${
          (isBalanceGreige && totalQuantityInMill
          ) && tab === BIT.AVAILABLE
          ? `(${+totalQuantityInMill} m)`
          : ''
        }`}</div>
        <div className={styles.lh27}>{`${
          productionSubTypeAttributeId
            ? PRODUCTION_SUB_ATTRIBUTE_ID[productionSubTypeAttributeId] + ' . '
            : ''
        } ${weaveTypeId ? WEAVE_PATTERN_REVERSE[weaveTypeId] + ' .' : ''} ${
          numberOfColors ? numberOfColors + ' colors .' : ''
        } ${color && productionSubType != 6 ? color + ' . ' : ''} ${
          threadcount ? threadcount + ' . ' : ''
        } ${construction ? construction + ' . ' : ''} ${
          minWidth ? `${Number(minWidth)} - ${Number(width)}` : width
        } in  ${loomType ? ' . ' + LOOM_TYPES_KEY[loomType] : ''}   ${
          warehouseName ? ' . ' + warehouseName : ''
        } ${warehouseLocation ? ', ' + warehouseLocation : ''}${
          (isBalanceGreige && millName) ? ' . ' + millName : ''
        }`}</div>
        <div className={styles.requirementBox}>
          <p className={styles.requirementTxt}>{PRODUCTION_SUB_TYPES_REVERSE[productionSubType]}</p>
        </div>
      </div>

      <div className="mt-8 mb-8">
        <div className={styles['button-switch']}>
          <div
            onClick={() => {
              setTab(BIT.AVAILABLE);
            }}
            className={styles[tab === BIT.AVAILABLE ? 'btn-selected' : 'btn-unselected']}>
            Available
          </div>
          <div
            onClick={() => {
              setTab(BIT.ARCHIVED);
            }}
            className={styles[tab === BIT.ARCHIVED ? 'btn-selected' : 'btn-unselected']}>
            Archived
          </div>
        </div>
      </div>

      {(!isBalanceGreige) ? (
      <>
      <Table
        className={styles.inventoryTable}
        columns={editInventoryColumns({
          setOpenDeleteModal,
          setOpenEditModal,
          setDiscardModal,
          handleStatusChange,
          tab,
        }).filter(baleColumnFilter)}
        dataSource={tableBaleData}
        pagination={false}
      />
      </>
      ) : 
      <>
      <Table
        className={styles.inventoryTable}
        columns={editBalanceGreigeColumns({
          setDiscardModal,
          handleStatusChange,
          tab,
        }).filter(baleColumnFilter)}
        dataSource={balanceGreigeBaleData}
        pagination={false}
      />
      </>
      }
      <InventoryManagementForm
        visible={openInventoryForm}
        onClose={() => setOpenInventoryForm(false)}
      />
      <Modal
        title="Delete"
        open={openDeleteModal.visibility}
        onCancel={() => setOpenDeleteModal({ visibility: false, record: null })}
        className="supplier-modal"
        footer={[
          <Button
            className={styles.clear}
            onClick={() => setOpenDeleteModal({ visibility: false, record: null })}>
            Close
          </Button>,
          <Button
            className={styles.apply}
            onClick={() => {
              handleDeleteBale(openDeleteModal.record);
            }}>
            Delete
          </Button>,
        ]}>
        <div>
          <p className={styles.uploadTxt}>Are you sure that you want to remove this bale?</p>
        </div>
      </Modal>
      <Modal
        title="Discard"
        open={discardModal.visibility}
        onCancel={() => setDiscardModal({ visibility: false, record: null })}
        className="supplier-modal"
        footer={[
          <Button
            className={styles.clear}
            onClick={() => setDiscardModal({ visibility: false, record: null })}>
            Close
          </Button>,
          <Button
            className={styles.apply}
            onClick={() => {
              isBalanceGreige ? handleStatusChange({ baleId: discardModal.record.id, status: BALE_STATUS_BALANCE_GREIGE.DISCARD }) :
              handleStatusChange({ baleId: discardModal.record.id, status: BALES_STATUS.DISCARD });
            }}>
            Discard
          </Button>,
        ]}>
        <div>
          <p className={styles.uploadTxt}>Are you sure that you want to discard this bale?</p>
        </div>
      </Modal>
      <Modal
        title="Edit bale"
        open={openEditModal.visibility}
        onCancel={() => setOpenEditModal({ visibility: false, record: null })}
        className="supplier-modal"
        destroyOnClose
        footer={null}>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            baleNumber: openEditModal.record?.baleNumber || '',
            quantity: openEditModal.record?.quantity || '',
            docketNumber: openEditModal.record?.docketNumber || '',
            sourcingPrice: openEditModal.record?.sourcingPrice || '',
            supplierId: openEditModal.record?.supplierId || '',
            supplier: openEditModal.record?.supplierName || '', // supplier name
            fulfilledBy: openEditModal.record?.fulfilledBy || '',
          }}>
          {({ errors, dirty, values, setValues, setFieldValue }) => {
            const isButtonDisabled = !dirty || (dirty && Object.keys(errors).length > 0);
            return (
              <>
                <div>
                  <FieldInput
                    // isRequired
                    name="baleNumber"
                    label="Bale no."
                    placeholder="Enter Bale Number"
                    // type="number"
                    // isUnit="%"
                  />
                  <div className="mt-4">
                    <FieldInput
                      // isRequired
                      name="quantity"
                      label="Quantity"
                      placeholder="Enter Quantity"
                      type="number"
                      isUnit="meter"
                    />
                  </div>
                  <div className="mt-4">
                    <FieldInput
                      // isRequired
                      name="docketNumber"
                      label="Docket no."
                      placeholder="Enter Docket Number"
                      // type="number"
                      // isUnit="%"
                    />
                  </div>

                  <div className="mt-4">
                    <FieldInput
                      // isRequired
                      name="sourcingPrice"
                      label="Sourcing price"
                      placeholder="Enter Sourcing price"
                      type="number"
                      isUnit="/ meter"
                    />
                  </div>

                  <div className="mt-4" />
                  <Select
                    isSupplier
                    name="supplier"
                    label="Supplier"
                    placeholder="Select Supplier"
                    onSearch={debouncedSupplierSearch}
                    onSelect={(supplierName, suppData) => {
                      setValues({
                        ...values,
                        supplierId: suppData?.label?.supplierId,
                        supplierName,
                      });
                    }}
                    options={mills?.data}
                  />

                  <div className="mt-4" />
                  <Select
                    isGlobal
                    fieldKey={'value'}
                    fieldValue={'label'}
                    name="fulfilledBy"
                    label="Fulfilled by"
                    onSelect={(x, suppTypeData) => {
                      setFieldValue('fulfilledBy', suppTypeData?.label?.value);
                    }}
                    options={FULFILLED_BY_OPTIONS}
                  />
                </div>
                <div className="footer">
                  <Button key="back" onClick={() => setOpenEditModal(false)} className="clear">
                    Close
                  </Button>
                  <Button
                    key="submit"
                    type="primary"
                    role="submit"
                    className={`apply ${isButtonDisabled && 'disabledButton'}`}
                    onClick={() => {
                      handleEditBale({ record: openEditModal.record, formValues: values });
                    }}
                    disabled={isButtonDisabled}>
                    Update
                  </Button>
                </div>
              </>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state, props) => ({
  materialData: state.fabricQualities.materialData,
  tableBaleData: state.inventoryData.inventoryBaleData,
  mills: state.user?.mills,
  balanceGreigeBaleData: state.inventoryData.balanceGreigeBaleData,
});

const mapDispatchToProps = (dispatch) => ({
  getBaleTableData: (data) => dispatch(getInventoryBaleData(data)),
  updateBaleDetails: (data) => dispatch(updateBaleData(data)),
  getMills: (data) => dispatch(getMillsAction({ ...data, isSession: true })),
  getBalanceGreigeBaleData: (data) => dispatch(getBalanceGreigeBaleData(data)),
  updateBalanceGreigeBaleData: (data) => dispatch(updateBalanceGreigeBaleData(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditInventory);
