export const STEP_1 = '1';
export const STEP_2 = '2';
export const STEP_3 = '3';
export const MIN_WIDTH = 20;
export const MAX_WIDTH = 200;
export const DEFAULT_DOMESTIC_MARGIN = 3;
export const DEFAULT_INTERNATIONAL_MARGIN = 12;

const STEPS = {
  [STEP_1]: {
    prev: null,
    next: STEP_2,
    keys: [
      'customerId',
      'category',
      'fabricCategoryId',
      'fabricSubcategoryId',
      'categoryComposition',
      'loomType',
    ],
  },
  [STEP_2]: {
    prev: STEP_1,
    next: STEP_3,
    keys: [
      'productionSubType',
      'qualitySpecification',
      'epi',
      'ppi',
      'weight',
      'width',
      'x',
      'y',
      'isPhysicalSwatchShared',
      'numberOfColors',
      'certifications',
      'image',
      'color',
    ],
  },
  [STEP_3]: {
    prev: STEP_2,
    next: null,
    keys: [
      'quantity',
      'targetPrice',
      'dueDate',
      'creditPeriod',
      'additionalDetails',
      'currencyId',
      'preferredQuoteType',
    ],
  },
};

export const FABRIC_TYPES = [
  {
    label: 'Knits',
    value: '1',
    unitValue: '1',
  },
  {
    label: 'Woven',
    value: '2',
    unitValue: '2',
  },
];

export const RT_GREIGE = '1';
export const RT_FINISHED_FABRIC = '2';

export const RFD_REQUIREMENT_INDEX = 1;
export const DYED_REQUIREMENT_INDEX = 2;
export const PRINTED_REQUIREMENT_INDEX = 3;

export const REQUIREMENT_TYPES = [
  {
    label: 'Greige',
    value: '1',
    typeValue: RT_GREIGE,
  },
  {
    label: 'RFD',
    value: '3',
    typeValue: RT_FINISHED_FABRIC,
  },
  {
    label: 'Dyed',
    value: '4',
    typeValue: RT_FINISHED_FABRIC,
  },
  {
    label: 'Printed',
    value: '5',
    typeValue: RT_FINISHED_FABRIC,
  },
  {
    label: 'Yarn Dyed',
    value: '2',
    typeValue: RT_GREIGE,
  },
];

export const PRODUCTION_SUB_TYPES = {
  Greige: '1',
  YarnDyed: '2',
  RFD: '3',
  Dyed: '4',
  Printed: '5',
  Denim: '6',
};
export const PRODUCTION_SUB_TYPES_REVERSE = {
  1:'Greige',
  2:'Yarn dyed',
  3:'RFD',
  4:'Dyed',
  5:'Printed',
  6:'Denim'
};

export const COUNTRY_TYPES = [
  {
    label: 'Domestic',
    value: '1',
  },
  {
    label: 'International',
    value: '2',
  },
  {
    label: 'Both',
    value: '3',
  },
];

export const CUSTOMER_KEYS = {
  Domestic: '1',
  International: '2',
  Both: '3',
};
export const CUSTOMER_TYPES = {
  1: 'Domestic customers',
  2: 'International customers',
  3: 'Both',
};

// Quality Specifications Tab
export const QUALITY_TABS = [
  {
    title: 'Greige',
    key: 'greige',
    value: 1,
    filters: [],
    default: '',
  },
  {
    title: 'Finished Fabric',
    key: 'finished_fabric',
    value: 2,
    filters: [],
    default: '',
  },
];

export const GRIEGE_QUALITY_SPECIFICATION = 1;
export const FINISHED_FABRIC_QUALITY_SPECIFICATION = 2;
export const YARN_DYED_QUALITY_SPECIFICATION = 3;
export const PRODUCT_TYPES = [
  // {
  //   id: 1,
  //   name: "Knits",
  // },
  {
    id: 2,
    name: 'Woven',
  },
];
export const REQUIREMENT_TYPES_TABS = [
  {
    id: '1',
    name: 'Greige',
  },
  {
    id: '2',
    name: 'Yarn dyed',
  },
  {
    id: '3',
    name: 'RFD',
  },
  {
    id: '4',
    name: 'Dyed',
  },
  {
    id: '5',
    name: 'Printed',
  },
  {
    id: '6',
    name: 'Denim',
  },
];
export const LOOM_TYPES = [
  {
    id: 1,
    name: 'Power Loom',
  },
  {
    id: 2,
    name: 'Sulzer',
  },
  {
    id: 3,
    name: 'Air Jet',
  },
];
export const LOOM_TYPES_KEY = {
  1: 'Power Loom',
  2: 'Sulzer',
  3: 'Air Jet',
  4: 'Water Jet',
};
export const CATEGORY_TYPES = [
  {
    id: 1,
    name: 'Cotton',
  },
  {
    id: 2,
    name: 'Rayon',
  },
];
export const DENIM_COLOR_TYPES = [
  {
    name: 'Indigo',
    id: '1',
  },
  {
    name: 'Blue',
    id: '2',
  },
  {
    name: 'Black',
    id: '3',
  },
  {
    name: 'IBST',
    id: '4',
  },
  {
    name: 'Ecru',
    id: '5',
  },
  {
    name:'Others',
    id: '6'
  }
];
export const DENIM_COLOR_TYPES_REVERSE = {
  1: 'Indigo',
  2: 'Blue',
  3: 'Black',
  4: 'IBST',
  5: 'Ecru',
};
export const WEIGHT_TABS = [
  {
    name: 'Before wash',
    id: 1,
  },
  {
    name: 'After wash',
    id: 2,
  },
];
export const WEAVE_PATTERN = [
  {
    name: '3/1',
    id: 1,
  },
  {
    name: '2/1',
    id: 2,
  },
  {
    name: 'Satin',
    id: 3,
  },
  {
    name: 'Dobby',
    id: 4,
  },
  {
    name: 'Knit',
    id: 5,
  },
];
export const WEAVE_PATTERN_REVERSE = {
  1: '3/1',
  2: '2/1',
  3: 'Satin',
  4: 'Dobby',
  5: 'Knit',
}
export const RFD_TYPES = [
  {
    name: 'RFD',
    id: '1',
  },
  {
    name: 'White',
    id: '2',
  },
];
export const DYED_TYPES = [
  {
    name: 'Light',
    id: '3',
  },
  {
    name: 'Medium',
    id: '4',
  },
  {
    name: 'Dark',
    id: '5',
  },
];
export const PRINTED_TYPES = [
  {
    name: 'Pigment',
    id: '6',
  },
  {
    name: 'Procion',
    id: '7',
  },
  {
    name: 'Discharge',
    id: '8',
  },
  {
    name: 'Digital',
    id: '9',
    typeValue: RT_FINISHED_FABRIC,
  },
];
export const PRODUCTION_SUB_ATTRIBUTE_ID={
  1:'RFD',
  2:'White',
  3:'Light',
  4:'Medium',
  5:'Dark',
  6:'Pigment',
  7:'Procion',
  8:'Discharge',
  9:'Digital'
}
export const ISSUE_TYPE = [
  {
    id: 1,
    name: 'Wet',
  },
  {
    id: 2,
    name: 'Torn',
  },
  {
    id: 3,
    name: 'L Short',
  },
  {
    id: 4,
    name: 'Others',
  },
];
export const WIDTH_SCALE = ['38', '48', '54', '63', '120'];

export const PHYSICAL_SWATCH_OPTIONS = [
  {
    label: 'Yes',
    value: '1',
  },
  {
    label: 'No',
    value: '0',
  },
];

export default STEPS;
