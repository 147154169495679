import { __computeConstructionAndThreadCount } from 'utilites/utils';
import {
  LOOM_TYPES_KEY,
  PRODUCTION_SUB_TYPES,
  PRODUCTION_SUB_TYPES_REVERSE,
  WEAVE_PATTERN,
  WEAVE_PATTERN_REVERSE,
} from '../TopQualities/config';
import editIcon from '../../../assets/images/image/ic-edit-blue.svg';
import trashIcon from '../../../assets/images/image/ic-trash.svg';
import infoIcon from '../../../assets/images/action/ic-info-blue.svg';
import certifiedTick from '../../../assets/CertifiedTick.svg';
import ProductImg from '../../../assets/img/Picture.svg';
import Chevron from 'assets/chevron_bottom.svg';
import style from './styles.module.scss';
import history from 'utilites/history';
import StatusDropdown from './EditInventory/StatusDropdown';
import StatusDropdownBalanceGreige from './EditInventory/StatusDropdownBalanceGreige';
import { Popover } from 'antd';
import { WEIGHT_UNIT_KG } from '../Customers/constants';
export const inventoryColumns = ({ sku, setRecordData, setViewImageModal }) => {
  const columns = [
    {
      title: '',
      dataIndex: 'skuImage',
      key: '1',
      render: (value, record) => (
        <div
          style={{ position: 'relative', display: 'inline-block',cursor:'pointer' }}
          onClick={() => {
            setViewImageModal(true);
            setRecordData(record);
          }}>
          <img
            src={
              value
                ? value
                : ProductImg
            }
            alt="product"
            style={{ width: 50, height: 50, borderRadius: 16 }}
          />
          <span
            style={{
              position: 'absolute',
              bottom: 1,
              left: 0,
              color: '#00142D',
              fontSize: '12px',
              width: '100%',
              textAlign: 'center',
              background: 'rgba(255, 255, 255, 0.60)',
              fontWeight: '600',
              whiteSpace: 'nowrap',        /* Prevents text wrapping */
              overflow: 'hidden',            /* Hides text that overflows */
              textOverflow: 'ellipsis', 
              paddingLeft:4    
            }}>
            {PRODUCTION_SUB_TYPES_REVERSE[record?.productionSubType]}
          </span>
        </div>
      ),

    },
    {
      title: 'Product name',
      dataIndex: 'productName',
      key: '2',
    },
    {
      title: 'SKU-ID',
      dataIndex: 'productId',
      key: '3',
    },
    {
      title: 'Specifications',
      dataIndex: 'specifications',
      key: '4',
      render: (value, record) => {
        const {
          threadcount,
          construction,
          width,
          loomType = 1,
          weaveTypeId,
          minWidth,
          productionSubType,
        } = record || {};
        return (
          <>
            <div className={style['specification-column']}>
              <div>{WEAVE_PATTERN_REVERSE[weaveTypeId] || threadcount}</div>
              <div className={style['dot']}></div>
              <div>{minWidth ? `${Number(minWidth)}-${Number(width)} inch` : construction}</div>
            </div>
            {productionSubType != 6 ? (
              <div className={style['specification-column']}>
                <div>{`${width} inch`}</div>
                <div className={style['dot']}></div>
                <div>{LOOM_TYPES_KEY[loomType]}</div>
              </div>
            ) : null}
          </>
        );
      },
    },
    {
      title: 'Warehouse name',
      dataIndex: 'warehouseName',
      key: '5',
      render: (value, record) => {
        return !record?.warehouseName
          ? '--'
          : !sku
          ? `${record?.warehouseName || ''}${record?.warehouseLocation ? ', ' : ''}${
              record?.warehouseLocation || ''
            }`
          : record?.warehouseName.split(',').map((name, index) => <div key={index}>{name}</div>);
      },
    },
    {
      title: 'Available Quantity',
      dataIndex: 'totalQuantityInWarehouse',
      key: '6',
      render: (value, record) => {
        return <div>{!value ? '--' : `${value} m`}</div>;
      },
    },
  ];

  if (!sku) {
    columns.push({
      title: 'Details',
      dataIndex: 'details',
      key: '7',
      render: (value, record) => {
        return (
          <p
            onClick={() => {
              history.push(
                `/sessioner/inventory-management/details/?record=${JSON.stringify(record)}`,
                record,
              );
            }}
            className={style.viewStyles}>
            View
            <img src={Chevron} alt="" />
          </p>
        );
      },
    });
  }

  return columns;
};

export const balanceGreigeColumns = [
{
  title: 'Product name',
  dataIndex: 'productName',
  key: '1',
},
{
  title: 'Specifications',
  dataIndex: 'specifications',
  key: '2',
  render: (value, record) => {
    const {
      threadcount,
      construction,
      width,
      loomType = 1,
      weaveTypeId,
      minWidth,
      productionSubType,
    } = record || {};
    return (
      <>
        <div className={style['specification-column']}>
          <div>{WEAVE_PATTERN_REVERSE[weaveTypeId] || threadcount}</div>
          <div className={style['dot']}></div>
          <div>{minWidth ? `${Number(minWidth)}-${Number(width)} inch` : construction}</div>
        </div>
        {productionSubType !== Number(PRODUCTION_SUB_TYPES.Denim) ? (
          <div className={style['specification-column']}>
            <div>{`${width} inch`}</div>
            <div className={style['dot']}></div>
            <div>{LOOM_TYPES_KEY[loomType]}</div>
          </div>
        ) : null}
      </>
    );
  },
},
{
  title: 'Processing mill name',
  dataIndex: 'millName',
  key: '3',
  render: (value, record) => {
    return !record?.millName
      ? '--'
      : `${record?.millName || ''}`
     },
    },
    {
  title: 'Available Quantity',
  dataIndex: 'totalQuantityInMill',
  key: '4',
  render: (value, record) => {
    return <div>{!value ? '--' : `${value} m`}</div>;
  },
  },
  {
  title: 'Details',
  dataIndex: 'details',
  key: '5',
  render: (value, record) => {
    return (
      <p
        onClick={() => {
          history.push(
            `/sessioner/inventory-management/details/?record=${JSON.stringify({...record,isBalanceGreige:true})}`,
            record,
          );
        }}
        className={style.viewStyles}>
        View
        <img src={Chevron} alt="" />
      </p>
    );
  },
}
];
export const editInventoryDataSource = [
  {
    baleNo: '7654',
    quantity: '1000 m',
    docketNo: '12345',
    productName: 'Rayon plain',
    sourcingPrice: '₹ 86.67/m',
    supplierName: 'Locofast Online Services Pvt Ltd.',
    fulfilledBy: 'Locofast',
    creationDate: 'Aug 12, 2023',
  },
  {
    baleNo: '7654',
    quantity: '1000 m',
    docketNo: '12345',
    productName: 'Rayon plain',
    sourcingPrice: '₹ 86.67/m',
    supplierName: 'Aaradhaya Creations',
    fulfilledBy: 'Locofast',
    creationDate: 'Aug 12, 2023',
  },
  {
    baleNo: '7654',
    quantity: '1000 m',
    docketNo: '12345',
    productName: 'Rayon plain',
    sourcingPrice: '₹ 86.67/m',
    supplierName: 'Locofast Online Services Pvt Ltd.',
    fulfilledBy: 'Locofast',
    creationDate: 'Aug 12, 2023',
  },
  {
    baleNo: '7654',
    quantity: '1000 m',
    docketNo: '12345',
    productName: 'Rayon plain',
    sourcingPrice: '₹ 86.67/m',
    supplierName: 'Locofast Online Services Pvt Ltd.',
    fulfilledBy: 'Locofast',
    creationDate: 'Aug 12, 2023',
  },
  {
    baleNo: '7654',
    quantity: '1000 m',
    docketNo: '12345',
    productName: 'Rayon plain',
    sourcingPrice: '₹ 86.67/m',
    supplierName: 'Locofast Online Services Pvt Ltd.',
    fulfilledBy: 'Locofast',
    creationDate: 'Aug 12, 2023',
  },
];
export const initialValues = {
  fabricType: null,
  x: '',
  y: '',
  productCategory: null,
  epi: '',
  ppi: '',
  subCategory: [],
  requirementType: null,
  width: '',
  loomType: '',
  weight: '',
  weightSpecification: '1',
  noOfColors: null,
  color: '',
  finishingType: '',
  colorIntensity: '',
  printingMethod: '',
  denimColor: '',
  denimWeight: '',
  minWidth: '',
  maxWidth: '',
  weavePattern: '',
  otherDenimColor:'',
  skuImage:null
};

export const editInventoryColumns = ({
  setOpenDeleteModal,
  setOpenEditModal,
  setDiscardModal,
  handleStatusChange,
  tab,
}) => [
  {
    title: 'Bale no.',
    dataIndex: 'baleNumber',
    key: '1',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: '2',
    render: (value, record) => {
      const content = (
        <>
          <div className={style['pop-box']}>
            <div className={style.popHeading}>Net weight</div>
            <div>
              <span className={style.popValue}>{record.netWeight || '--'}</span>
              <span>{WEIGHT_UNIT_KG}</span>
            </div>
          </div>
          <div className={style.seperator} />
          <div className={style['pop-box']}>
            <div>Gross weight</div>
            <div>
              <span className={style.popValue}>{record.grossWeight || '--'}</span>
              <span>{WEIGHT_UNIT_KG}</span>
            </div>
          </div>
        </>
      );
      return (
        <div className={style.flex}>
          <div>{+value}</div>
          <Popover
            placement="top"
            style={{ color: 'red' }}
            content={content}
            arrowContent={{ 'background-color': 'red' }}
            trigger="click">
            <img style={{ marginLeft: 12 }} src={infoIcon} alt="info icon" />
          </Popover>
        </div>
      );
    },
  },
  {
    title: 'Docket no.',
    dataIndex: 'docketNumber',
    key: '3',
  },
  {
    title: 'Sourcing Price',
    dataIndex: 'sourcingPrice',
    key: '4',
  },
  {
    title: 'Source LFB-ID',
    dataIndex: 'sourceLfbId',
    key: '55',
    render: (value) => {
      return <div>{!value ? '--' : value}</div>;
    },
  },
  {
    title: 'LFB-ID',
    dataIndex: 'generatedFabricId',
    tab: BALE_INVENTORY_TABS.AVAILABLE,
    key: '44',
    render: (value, record) => {
      return <div>{!value ? '--' : value}</div>;
    },
  },
  {
    title: 'Supplier name',
    dataIndex: 'supplierName',
    key: '5',
    render: (value, { isLcv }) => {
      return (
        <div className={style.flex}>
          {!!isLcv && <img style={{ marginRight: 8 }} src={certifiedTick} alt="certified tick" />}
          <div>{value}</div>
        </div>
      );
    },
  },
  {
    title: 'Fulfilled by',
    dataIndex: 'fulFilledByName',
    key: '6',
  },
  {
    title: 'Creation date',
    dataIndex: 'creationDate',
    tab: BALE_INVENTORY_TABS.ARCHIVED,
    key: '7',
  },
  {
    title: 'Last updated on',
    dataIndex: 'lastModifiedOn',
    tab: BALE_INVENTORY_TABS.AVAILABLE,
    key: '8',
    render: (value, record) => {
      return <div>{!value ? '--' : value}</div>;
    },
  },
  {
    title: '',
    dataIndex: '',
    tab: BALE_INVENTORY_TABS.ARCHIVED,
    render: (value, record) => {
      return (
        <div className="flex items-center">
          <div
            className={style.edit}
            onClick={() => {
              setDiscardModal({ visibility: true, record });
            }}>
            Discard
          </div>
          <div
            className={style.editBtn}
            onClick={() => {
              setOpenEditModal({ visibility: true, record });
            }}>
            <img src={editIcon} alt="edit icon" />
          </div>
          {/* <div
            className={style.pointer}
            onClick={() => {
              setOpenDeleteModal({ visibility: true, record });
            }}>
            <img src={trashIcon} alt="trash icon" />
          </div> */}
        </div>
      );
    },
  },
  {
    title: 'Status',
    tab: BALE_INVENTORY_TABS.AVAILABLE,
    dataIndex: '',
    render: (value, record) => {
      return <StatusDropdown handleStatusChange={handleStatusChange} record={record} />;
    },
  },
];
export const editBalanceGreigeColumns = ({
  setDiscardModal,
  handleStatusChange,
  tab,
}) => [
  {
    title: 'Bale no.',
    dataIndex: 'baleNumber',
    key: '1',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: '2',
    render: (value, record) => {
      const content = (
        <>
          <div className={style['pop-box']}>
            <div className={style.popHeading}>Net weight</div>
            <div>
              <span className={style.popValue}>{record.netWeight || '--'}</span>
              <span>kg</span>
            </div>
          </div>
          <div className={style.seperator} />
          <div className={style['pop-box']}>
            <div>Gross weight</div>
            <div>
              <span className={style.popValue}>{record.grossWeight || '--'}</span>
              <span>kg</span>
            </div>
          </div>
        </>
      );
      return (
        <div className={style.flex}>
          <div>{+value} m</div>
          <Popover
            placement="top"
            style={{ color: 'red' }}
            content={content}
            arrowContent={{ 'background-color': 'red' }}
            trigger="click">
            <img style={{ marginLeft: 12 }} src={infoIcon} alt="info icon" />
          </Popover>
        </div>
      );
    },
  },
  {
    title: 'Docket no.',
    dataIndex: 'docketNumber',
    key: '3',
  },
  {
    title: 'Sourcing Price',
    dataIndex: 'sourcingPrice',
    key: '4',
    render: (value) => {
      return <div>₹ {value} /m</div>;
    },
  },
  {
    title: 'Source LFB-ID',
    dataIndex: 'sourceLfbId',
    key: '55',
    render: (value) => {
      return <div>{value ? `LFB-${value}` : '--'}</div>;
    },
  },
  {
    title: 'LFB-ID',
    dataIndex: 'allocatedLfbId',
    tab: BALE_INVENTORY_TABS.AVAILABLE,
    key: '44',
    render: (value, record) => {
      return <div>{value ? `LFB-${value}` : '--'}</div>;
    },
  },
  {
    title: 'Mill Name',
    dataIndex: 'millName',
    key: '5',
    render: (value, { isLcv }) => {
      return (
        <div className={style.flex}>
          {!!isLcv && <img style={{ marginRight: 8 }} src={certifiedTick} alt="certified tick" />}
          <div>{value}</div>
        </div>
      );
    },
  },
  {
    title: 'Bale Status',
    dataIndex: 'status',
    tab: BALE_INVENTORY_TABS.ARCHIVED,
    key: '6',
    render: (value, record) => {
      return <div>{BALE_STATUS_BALANCE_GREIGE_REVERSE[record.status]}</div>
    },
  },
  {
    title: 'Creation date',
    dataIndex: 'createdOn',
    tab: BALE_INVENTORY_TABS.ARCHIVED,
    key: '7',
  },
  {
    title: 'Last updated on',
    dataIndex:'modifiedOn',
    tab: BALE_INVENTORY_TABS.AVAILABLE,
    key: '8',
    render: (value, record) => {
      return <div>{!value ? '--' : value}</div>;
    },
  },
  {
    title: '',
    dataIndex: '',
    tab: BALE_INVENTORY_TABS.ARCHIVED,
    render: (value, record) => {
      return (
        <div className="flex items-center">
          <div
            className={style.edit}
            onClick={() => {
              setDiscardModal({ visibility: true, record });
            }}>
            Discard
          </div>
        </div>
      );
    },
  },
  {
    title: 'Status',
    tab: BALE_INVENTORY_TABS.AVAILABLE,
    dataIndex: '',
    render: (value, record) => {
      return <StatusDropdownBalanceGreige handleStatusChange={handleStatusChange} record={record} />;
    },
  },
];
export const MIN_WIDTH = 20;
export const MAX_WIDTH = 200;
export const QUERY_CATEGORY = {
  1: 'Cotton',
  2: 'Rayon',
  3: 'Polyester',
  4: 'Blends',
  5: 'Denim',
};

export const BALE_INVENTORY_TABS = {
  AVAILABLE: 10,
  ARCHIVED: 20,
};

export const INVENTORY_TABS = {
  INVENTORY_MANAGEMENT: 10,
  BALANCE_GREIGE_AT_MILL: 20,
};

export const BALES_STATUS = {
  AVAILABLE: '10',
  ALLOCATED: '15',
  READY_FOR_PICKUP: '20',
  IN_TRANSIT: '30',
  DELIVERED: '40',
  DISCARD: '50',
  CANCELLED: '60',
};

export const BALES_STATUS_REVERSE = {
  [BALES_STATUS.AVAILABLE]: 'Available',
  [BALES_STATUS.ALLOCATED]: 'Allocated',
  [BALES_STATUS.READY_FOR_PICKUP]: 'Ready for pickup',
  [BALES_STATUS.IN_TRANSIT]: 'In transit',
  [BALES_STATUS.DELIVERED]: 'Delivered',
  [BALES_STATUS.DISCARD]: 'Discarded',
  [BALES_STATUS.CANCELLED]: 'Cancelled',
};

export const BALE_STATUS_DROPDOWN_MENU_OPTIONS = [
  {
    label: BALES_STATUS_REVERSE[BALES_STATUS.AVAILABLE],
    key: BALES_STATUS.AVAILABLE,
  },
  // {
  //   label: BALES_STATUS_REVERSE[BALES_STATUS.READY_FOR_PICKUP],
  //   key: BALES_STATUS.READY_FOR_PICKUP,
  // },
  // {
  //   label: BALES_STATUS_REVERSE[BALES_STATUS.IN_TRANSIT],
  //   key: BALES_STATUS.IN_TRANSIT,
  // },
  // {
  //   label: BALES_STATUS_REVERSE[BALES_STATUS.DELIVERED],
  //   key: BALES_STATUS.DELIVERED,
  // },
  // {
  //   label: BALES_STATUS_REVERSE[BALES_STATUS.DISCARD],
  //   key: BALES_STATUS.DISCARD,
  // },
];
export const BALE_STATUS_BALANCE_GREIGE = {
  UPLOADED: '10',
  AVAILABLE: '20',
  ALLOCATED: '30',
  UTILISED: '40',
  DISCARD: '50',
};
export const BALE_STATUS_BALANCE_GREIGE_REVERSE = {
  [BALE_STATUS_BALANCE_GREIGE.UPLOADED]: 'Uploaded',
  [BALE_STATUS_BALANCE_GREIGE.AVAILABLE]: 'Available',
  [BALE_STATUS_BALANCE_GREIGE.ALLOCATED]: 'Allocated',
  [BALE_STATUS_BALANCE_GREIGE.UTILISED]: 'Utilised',
  [BALE_STATUS_BALANCE_GREIGE.DISCARD]: 'Discarded',
};
export const BALE_STATUS_BALANCE_GREIGE_DROPDOWN_MENU_OPTIONS = [
  {
    label: BALE_STATUS_BALANCE_GREIGE_REVERSE[BALE_STATUS_BALANCE_GREIGE.AVAILABLE],
    key: BALE_STATUS_BALANCE_GREIGE.AVAILABLE,
  },
];

// TODO Make more dynamic
export const FULFILLED_BY = {
  Locofast: '1',
  Supplier: '2',
};

export const FULFILLED_BY_OPTIONS = [
  { label: 'Locofast', value: '1' },
  { label: 'Supplier', value: '2' },
];
export const WEIGHT_VALUES_REVERSE = {
  GSM: 10,
  Ounce: 12,
};
export const UPTO_TWO_DECIMAL_REGEX = /^\d+(\.\d{1,2})?$/;