import { handleActions } from 'redux-actions';

const INITIAL_STATE = {
 inventoryData: [],
 inventoryBaleData: [],
 balanceGreigeData:[],
 balanceGreigeBaleData:[]
};

const InventoryReducer = handleActions(
  {
    GET_INVENTORY_DATA: (state, action) => {
      return {
        ...state,
       inventoryData: action.payload,
      };
    },
    GET_INVENTORY_BALE_DATA: (state, action) => {
      return {
        ...state,
        inventoryBaleData: action.payload,
      };
    },
    GET_BALANCE_GREIGE_DATA: (state,action) => {
      return {
        ...state,
        balanceGreigeData: action.payload,
      };
    },
    GET_BALANCE_GREIGE_BALE_DATA: (state,action) => {
      return {
        ...state,
        balanceGreigeBaleData: action.payload,
      };
    }
  },

  INITIAL_STATE,
);

export default InventoryReducer;