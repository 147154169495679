import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Button, Modal, Table } from 'antd';
import { INVENTORY_TABS as IT, balanceGreigeColumns, inventoryColumns } from './base';
import InventoryManagementForm from './InventoryManagementForm';
import FileUpload from 'components/HOCs/FileUpload';
import { connect } from 'react-redux';
import { getMaterialData } from 'store/actions/FabricQualitiesActions';
import {
  getBalanceGreigeData,
  getInventoryData,
  getWarehouseIdData,
  uploadInventory,
} from 'store/actions/InventoryAction';
import StatusToggler from 'components/UI/Toggler/StatusToggler';
import style from '../TopQualities/style.module.scss';
import Filter from '../../../assets/img/filter_list.svg';
import FilterApplied from '../../../assets/img/Filter2.svg';
import RadioField from '../TopQualities/RadioField';
import { REQUIREMENT_TYPES_TABS } from '../TopQualities/config';
import { ReactComponent as Check } from '../../../assets/images/fabricQualities/Check.svg';
import ProductImg from '../../../assets/img/Picture.svg';

const InventoryManagement = ({
  getMaterialDataApi,
  getTableData,
  tableData,
  getWarehouseFILE,
  uploadInventory,
  balanceGreigeData,
  getBalanceGreigeData,
}) => {
  const [openInventoryForm, setOpenInventoryForm] = useState(false);
  const [filterModal, setOpenFilterModal] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(false);
  const [inventoryFileUpload, setInventoryFileUpload] = useState('');
  const [wareHouseId, setWarehouseId] = useState('');
  const [sku, setSKU] = useState(false);
  const [recordData, setRecordData] = useState([]);
  const [viewImageModal, setViewImageModal] = useState(false);
  const [isFilterApplied,setFilterApplied]=useState(false);
  const [tab, setTab] = useState(() => {
    return parseInt(sessionStorage.getItem("selectedTab")) || IT.INVENTORY_MANAGEMENT;
  });
  
  const handleTabChange = (newTab) => {
    setTab(newTab);
    sessionStorage.setItem("selectedTab", newTab);
  };
  
  const DEFAULT_DATA_LIMIT = 10;

  useEffect(() => {
    getMaterialDataApi();
  }, []);

  useEffect(() => {
    // Pagination will add in future
    let params = {
      // pageNumber: page,
      // limit: DEFAULT_DATA_LIMIT,
      sortBySKU: Number(sku),
    };
    getTableData(params);
  }, [page, loader, sku]);

  useEffect(() => {
    getBalanceGreigeData();
  }, [page, loader]);    

  const reloader = () => {
    setLoader(!loader);
  };

  const handleUploadClick = () => {
    setOpenUploadModal(true);
    getWarehouseFILE().then((resp) => {
      setWarehouseId(resp?.data?.data);
    });
  };
  const onUploadInventory = () => {
    let data = new FormData();
    data.append('file', inventoryFileUpload);
    uploadInventory(data).then(() => {
      setOpenUploadModal(false);
      reloader();
      setInventoryFileUpload('');
    });
  };
  const handleSelection = (id) => {
    if (selectedValues.includes(id)) {
      setSelectedValues(selectedValues.filter((value) => value !== id));
    } else {
      setSelectedValues([...selectedValues, id]);
    }
  };
  const handleFilterApplied = () => {
    let params = {
      sortBySKU: Number(sku),
      productionSubType: selectedValues.join(','),
    };
    getTableData(params).then((resp)=>{
      setFilterApplied(true);
      setOpenFilterModal(false);
    });
  };
  const clearFilter = async()=>{
   await setSelectedValues([]);
    let params = {
      sortBySKU: Number(sku),
    };
   await getTableData(params).then((resp)=>{
      setOpenFilterModal(false);
      setFilterApplied(false);
    });
  }
  return (
    <div className={styles.page}>
      <div className={styles.head}>
        <div className={styles.headline}>Inventory management</div>
        {tab === IT.INVENTORY_MANAGEMENT ? (
          <div>
            <button className={styles.skuBtn} onClick={() => setOpenInventoryForm(true)}>
              Create SKU
            </button>
            <button className="btn btn--blue-bg " onClick={() => handleUploadClick()}>
              Upload inventory
            </button>
          </div>
        ) : null}
      </div>
      <div className="mt-8 mb-8">
        <div className={styles['button-switch-main']}>
          <div
            onClick={() => {
              handleTabChange(IT.INVENTORY_MANAGEMENT)
              reloader();
            }}
            className={styles[tab === IT.INVENTORY_MANAGEMENT ? 'btn-selected-main' : 'btn-unselected-main']}>
            Inventory Management
          </div>
          <div
            onClick={() => {
              handleTabChange(IT.BALANCE_GREIGE_AT_MILL)
              reloader();
            }}
            className={styles[tab === IT.BALANCE_GREIGE_AT_MILL ? 'btn-selected-main' : 'btn-unselected-main']}>
            Balance greige at mill
          </div>
        </div>
      </div>
      <div className={styles.head}>
       {(tab === IT.INVENTORY_MANAGEMENT)?
       <div className="flex" onClick={() => setOpenFilterModal(true)}>
          <div className={styles['table-count']}>Total results: {tableData.length}</div>
          <div className={[styles['filter-box']]}>
            <img
              src={isFilterApplied ? FilterApplied : Filter}
              style={{ width: '100%', height: '100%', borderRadius: '50%' }}
              alt="LF"
            />
            <p className={styles['filter']}>Filter</p>
          </div>
        </div>
        : null}
        {tab === IT.BALANCE_GREIGE_AT_MILL ? (
          <div className={styles['table-count']}>Total results: {balanceGreigeData?.length}</div>
        ) : null}
        {tab === IT.INVENTORY_MANAGEMENT ? (
          <div style={{ marginBottom: 45 }}>
            <StatusToggler
              label={'View:'}
              onFalseLabel={'Warehouse'}
              onTrueLabel={'SKU'}
              style={style}
              condition={sku}
              onChange={(e) => {
                setSKU(e);
              }}
            />
          </div>
        ) : null}
      </div>
      {(tab===IT.INVENTORY_MANAGEMENT)?
      <Table
        className={styles.inventoryTable}
        columns={inventoryColumns({ sku, setRecordData, setViewImageModal })}
        dataSource={tableData}
        pagination={false}
        // pagination={{
        //   position: 'bottom',
        //   defaultPageSize: DEFAULT_DATA_LIMIT,
        //   total: tableData?.length,
        //   size: 'small',
        //   showSizeChanger: false,
        //   onChange: (page) => {
        //     setPage(Number(page));
        //   },
        // }}
        /> : 
      <Table
        className={styles.inventoryTable}
        columns={balanceGreigeColumns}
        dataSource={balanceGreigeData}
        pagination={false}
      />
      }

      <InventoryManagementForm
        visible={openInventoryForm}
        onClose={() => setOpenInventoryForm(false)}
        reload={reloader}
      />
      <Modal
        title="Upload inventory"
        open={openUploadModal}
        onCancel={() => setOpenUploadModal(false)}
        className="suppliers-modal"
        destroyOnClose
        footer={[
          <Button className={styles.clear} onClick={() => setOpenUploadModal(false)}>
            Close
          </Button>,
          <Button className={styles.apply} onClick={() => onUploadInventory()}>
            Submit
          </Button>,
        ]}>
        <>
          <div>
            <p className={styles.uploadTxt}>Upload CSV file</p>
          </div>
          <FileUpload file={inventoryFileUpload} setFile={setInventoryFileUpload} accept={'.csv'} />
          <div className={styles.downloadContainer}>
            <a
              className={styles.downloadAnchor}
              href={process.env.REACT_APP_INVENTORY_SAMPLE_FORMAT || ''}>
              Download sample format
            </a>
            <a className={styles.downloadAnchor} href={wareHouseId || ''}>
              Download warehouse ID
            </a>
          </div>
          <div className={styles.inventoryNote}>
            <div>
              <img src={require('../../../assets/images/Info.png')} alt="info" />
            </div>
            <div>{`Please note that the 'Net Weight' and 'Gross Weight' columns in the sheet are non-mandatory.`}</div>
          </div>
        </>
      </Modal>
      <Modal
        title={'Filter'}
        visible={filterModal}
        onCancel={clearFilter}
        className="supplier-modal"
        destroyOnClose
        footer={[
          <Button className={styles.clear} onClick={clearFilter}>
            Clear
          </Button>,
          <Button className={styles.apply} onClick={handleFilterApplied}>
            Apply
          </Button>,
        ]}>
        <div>
          <p>Fabric type</p>
          <div className="flex" style={{ flexWrap: 'wrap', rowGap: 6 }}>
            {REQUIREMENT_TYPES_TABS.map(({ id, name }) => (
              <div
                key={id}
                className={styles[selectedValues.includes(id) ? 'btnClick' : 'btn']}
                style={{ cursor: 'pointer' }}
                onClick={() => handleSelection(id)}>
                <div className="flex">
                  {selectedValues.includes(id) && (
                    <div style={{ marginRight: 4, marginTop: 3 }}>
                      <Check />
                    </div>
                  )}
                  <span>{name}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <Modal
        visible={viewImageModal}
        onCancel={() => setViewImageModal(false)}
        className={styles['img-modal']}
        style={{}}
        destroyOnClose
        footer={null}>
        <div>
          <img
            className={styles.imgBoxModal}
            src={recordData?.skuImage ? recordData?.skuImage : ProductImg}
            alt="Greige Fabric"
          />
        </div>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state, props) => ({
  materialData: state.fabricQualities.materialData,
  tableData: state.inventoryData.inventoryData,
  balanceGreigeData : state.inventoryData.balanceGreigeData,
});

const mapDispatchToProps = (dispatch) => ({
  getMaterialDataApi: () => dispatch(getMaterialData({ isSession: true })),
  getTableData: (data) => dispatch(getInventoryData(data)),
  getWarehouseFILE: () => dispatch(getWarehouseIdData()),
  uploadInventory: (data) => dispatch(uploadInventory(data)),
  getBalanceGreigeData: (data) => dispatch(getBalanceGreigeData(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(InventoryManagement);
